/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-syntax */
import {
    Container,
    Heading,
    Button,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    SimpleGrid,
    FormErrorMessage,
    Box,
    Text,
    useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import useLocale from '../lib/useLocale';

interface FormInput {
    value: string;
    error: string;
}

interface FormData {
    name: FormInput;
    lastName: FormInput;
    email: FormInput;
    message: FormInput;
    salsaLizano: FormInput; // Honeypot
}

export default function ContactForm() {
    const { format } = useLocale();
    const toast = useToast();

    const [inputs, setInputs] = useState<FormData>({
        name: { value: '', error: '' },
        lastName: { value: '', error: '' },
        email: { value: '', error: '' },
        message: { value: '', error: '' },
        salsaLizano: { value: '', error: '' },
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setInputs(prevInputs => ({
            ...prevInputs,
            [name]: {
                value,
                error: '',
            },
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let isValid = true;
        for (const key in inputs) {
            if (inputs[key].value === '' && key !== 'salsaLizano') {
                isValid = false;
                setInputs(prevInputs => ({
                    ...prevInputs,
                    [key]: {
                        ...prevInputs[key],
                        error: format('thisFieldIsRequired'),
                    },
                }));
            }
        }

        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!!inputs.email.value && !regex.test(inputs.email.value)) {
            isValid = false;
            setInputs(prevInputs => ({
                ...prevInputs,
                email: {
                    ...prevInputs.email,
                    error: format('pleaseEnterAValidEmail'),
                },
            }));
        }

        if (!isValid) return;

        setLoading(true);

        const body = Object.fromEntries(
            Object.entries(inputs).map(([key, value]) => [key, value.value])
        );

        try {
            const response = await fetch(`/api/mail`, {
                method: `POST`,
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            const data = await response.json();

            setLoading(false);

            if (response.status === 200) {
                setInputs({
                    name: { value: '', error: '' },
                    lastName: { value: '', error: '' },
                    email: { value: '', error: '' },
                    message: { value: '', error: '' },
                    salsaLizano: { value: '', error: '' },
                });
                toast({
                    title: format('thankYouForContactingUs'),
                    description: format('weWillGetBackToYouShortly'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom-right',
                });
            } else {
                toast({
                    title:
                        response.status === 400
                            ? data.message
                            : format('thereWasAnError'),
                    description:
                        response.status === 400 ? '' : format('pleaseTryAgain'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'bottom-right',
                });
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container py="12" maxW="lg">
            <Heading mb="10">{format('contactUs')}</Heading>
            <form onSubmit={handleSubmit}>
                <VStack spacing={5}>
                    <SimpleGrid columns={2} spacing={4} width="100%">
                        <FormControl id="name" isInvalid={!!inputs.name.error}>
                            <FormLabel>{format('name')}</FormLabel>
                            <Input
                                type="text"
                                focusBorderColor="primary.500"
                                value={inputs.name.value}
                                onChange={handleChange}
                                name="name"
                                aria-errormessage="name-error"
                                // this is handled by chakra
                                // aria-invalid={
                                //     inputs.name.error ? 'true' : 'false'
                                // }
                            />
                            {inputs.name.error && (
                                <FormErrorMessage id="name-error">
                                    {inputs.name.error}
                                </FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl
                            id="lastName"
                            isInvalid={!!inputs.lastName.error}
                        >
                            <FormLabel>{format('lastName')}</FormLabel>
                            <Input
                                type="text"
                                focusBorderColor="primary.500"
                                value={inputs.lastName.value}
                                onChange={handleChange}
                                name="lastName"
                                aria-errormessage="lastName-error"
                            />
                            {inputs.lastName.error && (
                                <FormErrorMessage id="lastName-error">
                                    {inputs.lastName.error}
                                </FormErrorMessage>
                            )}
                        </FormControl>
                    </SimpleGrid>
                    <FormControl id="email" isInvalid={!!inputs.email.error}>
                        <FormLabel>{format('email')}</FormLabel>
                        <Input
                            type="email"
                            focusBorderColor="primary.500"
                            value={inputs.email.value}
                            onChange={handleChange}
                            name="email"
                            aria-errormessage="email-error"
                        />
                        {inputs.email.error && (
                            <FormErrorMessage id="email-error">
                                {inputs.email.error}
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl
                        id="message"
                        isInvalid={!!inputs.message.error}
                    >
                        <FormLabel>{format('message')}</FormLabel>
                        <Textarea
                            focusBorderColor="primary.500"
                            value={inputs.message.value}
                            onChange={handleChange}
                            name="message"
                            aria-errormessage="message-error"
                        />
                        {inputs.message.error && (
                            <FormErrorMessage id="message-error">
                                {inputs.message.error}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    <FormControl id="salsaLizano" display="none">
                        <FormLabel>Salsa Lizano</FormLabel>
                        <Input
                            type="text"
                            focusBorderColor="primary.500"
                            value={inputs.salsaLizano.value}
                            onChange={handleChange}
                            name="salsaLizano"
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        variant="solid"
                        size="lg"
                        colorScheme="primary"
                        w="100%"
                        disabled={loading}
                    >
                        {format('sendMessage')}
                    </Button>
                </VStack>
            </form>
            <Box pt="12">
                <Text>{format('emailUs')}</Text>
                <Button
                    variant="link"
                    color="primary.900"
                    as="a"
                    href="mailto:en.base.studio@gmail.com"
                >
                    en.base.studio@gmail.com
                </Button>
                <Text mt="5">{format('phone')}</Text>
                <Button
                    variant="link"
                    color="primary.900"
                    as="a"
                    href="tel:+50684010525"
                >
                    +(506) 8401-0525
                </Button>
            </Box>
        </Container>
    );
}
