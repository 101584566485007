import { graphql, PageProps } from 'gatsby';
import { Box, Center, Show } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import { SanityPage } from '../../graphql-types';
import Content from '../components/Content';
import useLocale from '../lib/useLocale';
import ContactForm from '../components/ContactForm';

interface ContactPageProps extends PageProps {
    data: {
        page: SanityPage;
    };
}

export default function ContactPage({ data }: ContactPageProps) {
    const { translate } = useLocale();
    return (
        <div>
            <SEO
                title={
                    data.page.title ? translate(data.page.title) : 'Contacto'
                }
            />
            <Box display="grid" gridTemplateColumns="1fr auto" h="100%">
                <Show above="lg">
                    <Box>
                        <StaticImage
                            src="../images/productos.jpg"
                            alt="Productos"
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </Box>
                </Show>
                <Center
                    w="100vw"
                    maxW={{
                        lg: 'container.sm',
                    }}
                >
                    <Content data={data.page.content} />
                    <ContactForm />
                </Center>
            </Box>
        </div>
    );
}

export const query = graphql`
    query ContactPageQuery($language: String!) {
        page: sanityPage(slug: { current: { eq: "contacto" } }) {
            ...SanityPageFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
